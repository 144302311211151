<template>
  <div id="organizationManagement" class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">鉴定机构管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">鉴定机构分成</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">导出名单</a>
        </span>
      </div>

      <div class="framePage-body">
      <div class="ovy-a">
        <div class="SettlementInformation">
        <div style="padding:20px">
            <div title="考试结算状态" class="searchboxItem ci-full">
              <span class="itemLabel">考试结算状态:</span>
                <el-link  @click="getSessionsList('1')" :type="stu == '1'?'primary':''" :underline="false">全部</el-link>
                <el-link  @click="getSessionsList('2')" :type="stu == '2'?'primary':''" :underline="false">已结算</el-link>
                <el-link  @click="getSessionsList('3')" :type="stu == '3'?'primary':''" :underline="false">未结算</el-link>
                </div>
          <div title="考试场次" class="searchboxItem ci-full" style="display:flex; margin-top:15px">
              <span class="itemLabel">考试场次:</span>
                <div class="sessions">
                    <p @click="getImformation(item,index)" v-for="(item,index) in sessionsList" :key="index" :class="isactive == index ? 'addclass' : ''">{{item.examOccupationName}}-{{item.examLevel}}-{{item.examTheoryStart | moment}}-
{{item.examTheoryEnd | moment}}-{{item.examPracticePlace}}</p>
                </div>
                
 </div>
          </div>
        </div>
          <h3>考试信息</h3>
          <div class="testInformation">
           <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="studentMation"
        >
          <el-form-item label="考试工种：" prop="examOccupationId">
               <p>{{ ruleForm.examOccupationName }}</p>
          </el-form-item>
          <el-form-item label="考试级别：" prop="examLevel">
             <p>{{ ruleForm.examLevel }}</p>
          </el-form-item>
          <el-form-item label="报名人数：" prop="examLevel">
            <p>{{ ruleForm.examPeopleJoin}}</p>
          </el-form-item>
           <el-form-item label="交费人数：" prop="examLevel">
            <p>{{ ruleForm.payStateNum }}</p>
          </el-form-item>
          <el-form-item label="理论考试时间：" prop="theoryTestTime">
            <p>
              {{ ruleForm.examTheoryStart | moment }} -
              {{ ruleForm.examTheoryEnd | moment }}
            </p>
          </el-form-item>
          <el-form-item label="理论考试地点：" prop="theoryTestTime">
            <p>
              {{ ruleForm.examTheoryPlace }}
            </p>
          </el-form-item>
          <el-form-item label="实操考试时间：" prop="practiceTestTime">
            <p>
              {{ ruleForm.examPracticeStart | moment }} -
              {{ ruleForm.examPracticeEnd | moment }}
            </p>
          </el-form-item>
           <el-form-item label="实操考试地点：" prop="examPracticePlace">
            <p>
              {{ ruleForm.examPracticePlace }}
            </p>
          </el-form-item>
          <el-form-item
            label="综合评审时间："
            prop="examReviewStart"
            v-if="ruleForm.examReviewStart"
          >
            <p>
              {{ ruleForm.examReviewStart | moment }} -
              {{ ruleForm.examReviewEnd | moment }}
            </p>
          </el-form-item>
           <el-form-item label="综合评审地点：" prop="examReviewPlace" v-if="ruleForm.examReviewPlace">
            <p>
              {{ ruleForm.examReviewPlace }}
            </p>
          </el-form-item>
        </el-form>
          </div>
        <!-- 查询 -->
        <h3>报名学员名单</h3>
        <div class="operationControl flexdc" style="align-items: flex-start">
         
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="userName"
                type="text"
                size="small"
                placeholder="请输入姓名"
                clearable
              />
            </div>
            <div title="报名方式" class="searchboxItem ci-full">
              <span class="itemLabel">报名方式:</span>
              <el-select v-model="applyType" placeholder="请选择报名方式" size="small"  clearable>
                <el-option
                  v-for="item in applyTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div title="交费状态" class="searchboxItem ci-full">
              <span class="itemLabel">交费状态:</span>
              <el-select v-model="payState" placeholder="请选择" size="small"  clearable>
                <el-option
                  v-for="item in payStateOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
             <div title="结算状态" class="searchboxItem ci-full">
              <span class="itemLabel">结算状态:</span>
              <el-select v-model="settlementState" placeholder="请选择" size="small"  clearable>
                <el-option
                  v-for="item in SettlementOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getListData()"
              >搜索</el-button
            >
          </div>
          <div class="searchbox">
      
        <el-button
          type="primary"
          class="bgc-bv"
          round
          @click="handleBatchSettlement('false')"
          >设为未结算</el-button
        >
        <el-button
          type="primary"
          class="bgc-bv"
          round
          @click="handleBatchSettlement('true')"
          >设为已结算</el-button
        ><el-button
          type="primary"
          class="bgc-bv"
          round
          @click="handleBatchExport('all')"
          >导出所有学员</el-button
        >
        <el-button
          type="primary"
          class="bgc-bv"
          round
          @click="handleBatchExport('false')"
          >导出未结算学员</el-button
        >
         <el-button
          type="primary"
          class="bgc-bv"
          round
          @click="handleBatchExport('true')"
          >导出已结算学员</el-button
        >
      </div>
        </div>
        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
               <el-table
          ref="multipleTable"
          :data="listData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          row-key="evaluateExamUserId"
          @selection-change="change"
          stripe
        >
          <el-table-column
            type="selection"
            width="50px"
            align="center"
            :reserve-selection="true"
            :selectable="selectable"
          ></el-table-column>
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
          />
          <el-table-column
            label="姓名"
            align="center"
            show-overflow-tooltip
            prop="userName"
          />
          <el-table-column
                label="报名时间"
                align="left"
                prop="signDate"
                show-overflow-tooltip
              >
              <template slot-scope="scope">
              {{
                scope.row.signDate | moment
              }}</template>
              </el-table-column>
          <el-table-column
            label="报名方式"
            align="center"
            show-overflow-tooltip
            prop="applyType"
          >
            <template slot-scope="scope">
              {{ $setDictionary("EVALUATE_APPLY_TYPE", scope.row.applyType) }}
            </template>
          </el-table-column>
          <el-table-column label="是否交费" align="center">
            <template slot-scope="scope">
              {{scope.row.payState ? '已交费':'未交费'}}
            </template>
          </el-table-column>
           <el-table-column
                label="结算状态"
                align="left"
                prop="settlementState"
                show-overflow-tooltip
              >
              <template slot-scope="scope">
                {{ $setDictionary("EVALUATE_SETTLEMENT_STATE", scope.row.settlementState) }}
              </template>
              </el-table-column>
        </el-table>
          </div>
        </div>
         <div class="pageNum">
          <el-pagination
            :total="fenYe.total"
            :page-size="fenYe.size"
            :current-page="fenYe.currentPage"
            background
            :page-sizes="[10, 20, 30, 40, 50]"
            layout="total, sizes,prev, pager, next,jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
    </div>
</div>
  </div>
</template>

<script>
import List from "@/mixins/List";
export default {
  components: {
  },
    mixins: [List],
  data() {
    return {
      userName:'', //姓名
      applyType:'', //报名方式
      payState: "", //交费状态
      settlementState:'', //结算状态
      areatreeList: [], // 行政区划
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      applyTypeOptions:[],
      SettlementOptions:[],
       payStateOptions: [
        {
          value: true,
          label: "已交费",
        },
        {
          value: false,
          label: "未交费",
        },
      ],
      sessionsList:[],
      ruleForm:{},
      listData:[],
       // 分页
      fenYe: {
        currentPage: 1, // 当前页数
        size: 10,
        total: null, // 共多少页
      },
      stu:'1',
      multipleTable:[],
    };
  },
  watch: {
    compId: function(val) {
      if (val == "") {
        this.CompanyList = [];
      }
    },
  },
  created() {
    this.getSessionsList('1')
    this.getSettlementOptions()
  },
  methods: {
    //码值获取
    getSettlementOptions() {
      const OptionsList = this.$setDictionary("EVALUATE_SETTLEMENT_STATE", "list");
      const applyTypeOptions = this.$setDictionary("EVALUATE_APPLY_TYPE", "list");
      const list = [],applyTypeOptionsList = [];
      for (const key in OptionsList) {
          list.push({
            value: key,
            label: OptionsList[key]
          });
      }
      for (const key in applyTypeOptions) {
          applyTypeOptionsList.push({
            value: key,
            label: applyTypeOptions[key]
          });
      }
      this.SettlementOptions = list;
      this.applyTypeOptions = applyTypeOptionsList;

    },
   getSessionsList(stu) {
     this.stu = stu;
     this.examId = '';
     const parmar = {
       compId:this.$route.query.compId,
       settlement:stu == '2' ? true:stu == '3' ? false:'',
     }
     this.$post('/evaluate/share/settlement',parmar).then(ret => {
       this.sessionsList = ret.data;
        this.isactive = null
       this.ruleForm = {}
        this.listData = []
     })
   },
    // 获取考试信息和学员名单
    getImformation(item,index) {
      this.isactive = index;
      this.examId = item.examId
      this.ruleForm = {
        ...item
      }
      this.getListData(this.fenYe.currentPage, this.fenYe.size)
     
    },
     // 每页多少条
    handleSizeChange(val) {
      this.fenYe.size = val;
      this.fenYe.currentPage = 1;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 当前页数
    handleCurrentChange(val) {
      this.fenYe.currentPage = val;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
     // 序号
    indexMethod(index) {
      return (this.fenYe.currentPage - 1) * this.fenYe.size + index + 1;
    },
    // 获取数据
    getListData(page = 1, pagesize = this.fenYe.size) {
      if(!this.examId) {
         this.$message({
          message:'请先选择考试场次,再进行搜索',
          type:'warning'
        })
        return false
      } else {
      const params = {
        pageNum: page,
        pageSize: pagesize,
        examId:this.examId,
        payState:this.payState
        };
        if (this.userName) {
          params.userName = this.userName;
        }
        if (this.applyType) {
          params.applyType = this.applyType;
        }
         if (this.settlementState) {
          params.settlementState = this.settlementState;
        }
      this.$post("/evaluate/share/sign", params)
        .then((res) => {
          this.listData = res.data.list;
           this.fenYe.total = res.data.total;
           this.indexMethod()
        })
        .catch(() => {
          return;
        });
        }
    },
    change(val) {
      //  this.$refs.multipleTable.toggleRowSelection(val);
      this.multipleTable = val;
    },
    //不结算的禁用
     selectable(row,index) {
      if(row.settlementState == '30') {
        return false
      } else {
        return true
      }
    },
    //设为已结算&&未结算
    handleBatchSettlement(settlementStu) {
       if (this.multipleTable.length > 0) {
         let evaluateExamUserIds = []
        evaluateExamUserIds = this.multipleTable.map((t) => {
          return t.evaluateExamUserId;
        });
        const parmar = {
          evaluateExamUserIds:evaluateExamUserIds,
          settlement:settlementStu == 'false'?false:true,
          examId:this.examId
        }
        this.$post('/evaluate/share/settlement/modify',parmar).then(ret => {
          if(ret.status == '0') {
            this.$message({
              message:ret.message,
              type:'success'
            })
            this.getListData(this.fenYe.currentPage, this.fenYe.size);
             this.$refs.multipleTable.clearSelection();
          }
        })
      } else {
        this.$message({
          message: "请至少勾选一个学员!",
          type: "warning",
        });
      }
    },
    //导出学员
    handleBatchExport(exportSettlement) {
      if(!this.examId){
        this.$message({
          message:'请先选择考试场次,再进行导出',
          type:'warning'
        })
        return false
      } else {
         const parmar = {
          settlementState:exportSettlement == 'false'?'10':exportSettlement == 'true'? '20':'',
          examId:this.examId
        }
      this.$post('/evaluate/share/sign/excel',parmar).then(ret => {
          if(ret.status == '0') {
            window.open(ret.data)
          }
      })
      }
      
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>

<style lang="less" scope>
.SettlementInformation {
  background:#f3f3f3;
  .itemLabel {
    min-width:7rem
  }
  .el-link {
    margin:0 10px;
  }
  .sessions {
    p {
      line-height:25px;
          cursor: pointer
    }
    .addclass {
      color:#409EFF
    }
  }
}
.testInformation {
    background:#f3f3f3
}
.studentMation {
  display: flex;
  flex-wrap: wrap;
      padding: 20px;
  .el-form-item {
    width: 50%;
    margin-bottom: 5px;
    .el-form-item__label  {
    line-height:20px
}
.el-form-item__content {
    line-height:20px
}
  }
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #5c6be8
}
</style>
